<template>
	<v-container fluid style="width: 90%">
		<v-layout class="my-2">
			<!-- <v-flex> -->
			<!-- <h3 class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue">Leave</h3> -->
			<!-- </v-flex> -->
			<v-flex class="text-right">
				<v-btn depressed tile class="custom-grey-border custom-bold-button" v-on:click="goBack">
					<v-icon small left>mdi-keyboard-backspace</v-icon>
					Back
				</v-btn>
			</v-flex>
		</v-layout>
		<perfect-scrollbar
			:options="{ suppressScrollX: true }"
			class="scroll custom-box-top-inner-shadow"
			style="max-height: 85vh; position: relative"
		>
			<v-card flat class="custom-setting-height custom-grey-border remove-border-radius">
				<v-card-title class="headline grey lighten-4">
					<v-row>
						<v-col md="6" class="py-0 my-auto">
							<h3 class="font-weight-700 custom-headline color-custom-blue" style="margin: 3.5px 0px">
								Ip Address #
							</h3>
						</v-col>
						<template>
							<v-col md="6" class="text-right py-0">
								<v-btn
									color="blue darken-4 mr-3"
									class="white--text my-auto"
									style="padding-left: 16px !important"
									depressed
									tile
									@click="AddLeave"
									x-small
									min-height="26px"
								>
									<v-icon left size="26">mdi-plus</v-icon>
								</v-btn>
								<v-btn
									color="blue darken-4"
									class="white--text my-auto"
									depressed
									tile
									@click="updateOrcreate"
									:disabled="pageLoading || !formValid"
								>
									<span class="font-size-16 font-weight-600">Save</span>
								</v-btn>
							</v-col>
						</template>
					</v-row>
				</v-card-title>
				<v-card-text class="p-6 font-size-16">
					<v-form
						ref="leave_category"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updateOrcreate"
					>
						<v-row>
							<v-col md="3"><label class="fw-600 fs-16">Name</label></v-col>
							<v-col md="3"><label class="fw-600 fs-16 required">Ip Address</label></v-col>
							<v-col md="2"><label class="fw-600 fs-16">Status</label></v-col>
							<v-col md="1"><label class="fw-600 fs-16"></label></v-col>
							<v-col md="2"><label class="fw-600 fs-16"></label></v-col>
						</v-row>
						<v-row v-for="(type, index) in leaveList" :key="index">
							<v-col md="3" class="pb-4">
								<!-- <label class="input-label">Leave Type</label> -->
								<v-text-field
									hide-details
									outlined
									class="textCapitalize"
									placeholder="Name"
									v-model.trim="type.name"
									:rules="[vrules.required(type.name, 'Name')]"
									:class="{
										required: !type.name,
									}"
								>
								</v-text-field>
							</v-col>

							<v-col md="3" class="pb-4">
								<!-- <label class="input-label">Max Days</label> -->
								<v-text-field
									hide-details
									outlined
									placeholder="Ip Address"
									v-model="type.ip_address"
									:rules="[vrules.required(type.ip_address, 'ip_address')]"
									:class="{
										required: !type.ip_address,
									}"
								>
								</v-text-field>
							</v-col>
							<v-col md="2" class="pb-4">
								<!-- <label class="input-label fw-500 ">Interval</label> -->
								<v-autocomplete
									:items="status"
									:menu-props="{ bottom: true, offsetY: true }"
									outlined
									clearable
									height="30px"
									hide-details
									v-model="type.status"
									item-text="title"
									item-value="value"
									placeholder="Select Status"
								>
									<template #item="{ item }">
										<div class="text-uppercase pt-1 fw-500">{{ item.title }}</div>
									</template>
								</v-autocomplete>
							</v-col>

							<v-col md="1" class="pb-4">
								<v-tooltip top content-class="custom-top-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<v-icon
											v-on:click="deleteCommon(type.id, index)"
											color="deep-orange"
											v-bind="attrs"
											v-on="on"
											>mdi-delete</v-icon
										>
									</template>
									<span>Click here to delete</span>
								</v-tooltip>
							</v-col>
							<v-col md="2" class="pb-4"></v-col>
						</v-row>
						<template v-if="leaveList.length < 1">
							<p class="m-0 row-not-found text-center">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no {{ type }} at the moment.
							</p>
						</template>
					</v-form>
				</v-card-text>
			</v-card>
		</perfect-scrollbar>
	</v-container>
</template>

<script>
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { map } from "lodash";
export default {
	name: "leave-setting",
	data() {
		return {
			customerId: 0,
			formValid: true,
			pageLoading: false,
			leaveList: [
				{
					name: null,
					ip_address: null,
					status: 1,
				},
			],
			status: [
				{
					title: "Active",
					value: 1,
				},
				{
					title: "In-Active",
					value: 0,
				},
			],
		};
	},
	watch: {
		"$route.query": {
			deep: true,
			handler(query) {
				if (query && query.tab == "leave_category") {
					this.getLeaveDays();
				}
			},
		},
	},
	methods: {
		updateOrcreate() {
			const formErrors = this.validateForm(this.$refs["leave_category"]);

			this.$refs["leave_category"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["leave_category"].validate()) {
				return false;
			}
			this.$store
				.dispatch(POST, {
					url: "setting/ip_address",
					data: { categories: this.leaveList },
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Ip Address Assign successfully.",
						},
					]);
					this.getLeaveDays();
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateDays(days, typedata) {
			let decimals = days - Math.floor(days);
			if (decimals > 0) {
				typedata.maxdays = Math.floor(days) + 0.5;
			} else {
				typedata.maxdays = Math.floor(days);
			}
		},
		AddLeave() {
			this.leaveList.push({
				text: null,
				number: null,
			});
		},
		deleteCommon(categoriesid, index) {
			if (categoriesid) {
				this.deleteSettingLeave(categoriesid, index);
			} else {
				this.deleteLeaveDay(index);
			}
		},
		deleteLeaveDay(index) {
			if (this.leaveList.length > 1) {
				this.leaveList.splice(index, 1);
			}
		},
		deleteSettingLeave(categoriesid, index) {
			this.$store
				.dispatch(DELETE, {
					url: "setting/ip_address/" + categoriesid,
				})
				.then(() => {
					if (this.leaveList.length > 0) {
						this.leaveList.splice(index, 1);
					}
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "IpAddress Deleted successfully.",
						},
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getLeaveDays() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, {
					url: "setting/ip_address",
				})
				.then((data) => {
					this.leaveList = data;
					this.leaveList = map(this.leaveList, (row) => {
						return { ...row, category: row?.name?.replace("-", " ") };
					});
				})
				.catch((error) => {
					this.logError(error);
					this.goBack();
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	/* getStaffSetting() {
        
        this.$store
          .dispatch(GET, { url: "setting/engineer" })
          .then(({ data }) => {
            this.setting = data;
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.pageLoading = false;
          });
      },
      updateStaffSetting() {
        
        this.pageLoading = true;
        this.$store
          .dispatch(PATCH, { url: "setting/engineer", data: this.setting })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.pageLoading = false;
          });
      },*/
	mounted() {
		this.getLeaveDays();
	},
	beforeMount() {
		this.customerId = this.$route.params.id;
	},
};
</script>
